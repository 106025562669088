export default {
    data () {
        return {
            userAlreadyAskedToPrintHisCoupon: false,
        }
    },
    methods: {


        userAskedToPrintCouponsNow(){
            this.log("userAskedToPrintCouponsNow()", 'function');
            this.currentPage = "printcoupon";
        },

        userAskedToPrintCouponsLater(){
            this.log("userAskedToPrintCouponsLater()", 'wip');
            /* WIP - à bien tester
            POST – api/v1/coupons /send-email/print-link – “idparticipation” en paramètre =>
            Envoie un mail AFTERSURVEY lié au refhd de l’action avec un lien vers la page 1
            */
            // * let data = {idparticipation: this.participationKeyFromUrl};

            let data = {
                "idparticipation": this.participationKeyFromUrl,
                "idParticipationType": this.getIdOfRepartCode('HCDCPRINT'),
            };
            this.postToAxios(this.callConfigs.postTriggerMailSendForUser, data);
        },

        userAskedToPrintCoupons(){
            this.log("userAskedToPrintCoupons()", 'wip');
            this.userAlreadyAskedToPrintHisCoupon = true;
            this.pendingParticipationSent = true;
            /* WIP - à bien tester
            POST – api/v1/coupons/print – “idparticipation” en paramètre =>
            confirme que le conso veut imprimer sont coupon, renverra le directement le contenu du PDF
            (donc il ne faut pas chercher le dossier sur un serveur)
            */
            // * let data = {idparticipation: this.participationKeyFromUrl};

            let data = {
                "idparticipation": this.participationKeyFromUrl,
                "idParticipationType": this.getIdOfRepartCode('HCDCPRINT'),
            };
            
            this.postToAxios(this.callConfigs.postPrintCouponAndGetPdf, data);
        },

        mailAfterSurveyWasSent(){
            this.log("Mail was triggered", 'green');
            this.goto('confirmationMail');
        },
        mailAfterSurveyWasNotSent(error){
            this.log("Mail was not triggered", 'red');
            this.log(error);
            this.iGotThisErrorFromActionApi(error, this.$t('errors.mailWithCouponsNotSent'));
        },

        decodeBase64(embasedData){
            // Function receive data encoded in base 64
            let decodedData = atob(embasedData);
            /* This way */
            var uint8Array = new Uint8Array(decodedData.length);
            for (var i = 0; i < decodedData.length; i++) {
                uint8Array[i] = decodedData.charCodeAt(i);
            }
            /* Or this way */
            // Alternatively, create Uint8Array directly from base64-decoded data
            // const uint8Array = new Uint8Array(decodedData.split('').map(char => char.charCodeAt(0)));
            return uint8Array;
        },

        printPdf(pdfData){
            let pdf = this.decodeBase64(pdfData);
            let pdfFile = new Blob([pdf], {type: "application/pdf"});
            let pdfUrl = URL.createObjectURL(pdfFile);
            // We have now an url, we need to open the file without opening a new window.


            // first, remove previous iframe to avoid confusion and have a clean DOM
            let previousIframe = document.querySelector('#print_pdf');
            if(previousIframe){
                previousIframe.remove()
            }
            // then we need a new iframe with the pdf as content
            let iframe = document.createElement('iframe');
            // naming, so it can be deleted later
            iframe.id = 'print_pdf'
            iframe.name = 'print_pdf'
            iframe.style.display = "none";
            iframe.src = pdfUrl;
            document.body.appendChild(iframe);
            // as soon as it is loaded => print
            iframe.onload = () => {
                setTimeout(()=>{
                    iframe.focus();
                    iframe.contentWindow.print();

                    this.pendingParticipationSent = false;
                    this.currentPage = 'confirmationCoupon';

                }, 1);
            }           
            iframe.onerror = event => {
                console.error("Error loading iframe:", event);
                // Reactive button on form page
                this.userAlreadyAskedToPrintHisCoupon = false;
                this.pendingParticipationSent = false;
            };
        },



        printableCouponIsReceived(data){
            this.log("Print coupon is received", 'green');
            this.printPdf(data.data);
        },

        printableCouponIsNotReceived(error){
            this.log("Print coupon is not received", 'red');
            this.iGotThisErrorFromActionApi(error, this.$t('errors.printCouponsNotWorking'));

            // reactive bouton for another try
            this.userAlreadyAskedToPrintHisCoupon = false;
            this.pendingParticipationSent = false;
        },

    },
}
