<template>
    <div id="counterWrapper">

        <!-- Counter: participations left -->
        <p v-if="counterType === 'participationsLeft'">
            {{$t('counterText.participationLeft.before')}} <span class="counterNumberText">{{displayedNumber}}</span> {{$t('counterText.participationLeft.after')}}
        </p>

        <!-- Counter: days left -->
        <p v-if="counterType === 'daysLeft'">
            {{$t('counterText.daysLeft.before')}} <span class="counterNumberText">{{displayedNumber}}</span> {{$t('counterText.daysLeft.after')}}
        </p>
        
    </div>
</template>

<script>

    export default {
        name: "participationcounter",

        props:{
            counterType: {
                type: String,
                required: true
            },
            displayedNumber:{
                type: Number,
                required: true,
            default: 0
            },
        },

    }
</script>

<style lang="scss" scoped>

</style>