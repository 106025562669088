export const en = {
    general: {
        'world': 'Hello world!',
        'Y': 'Yes',
        'N': 'No',
    },

    gdpr: {
        'title': "Privacy notice"
    },

    auth: {
        form: {
            login: "Login",
            username:"Nom d'utilisateur",
            usernamePlaceholder: "Indiquez votre nom d'utilisateur",
            password: "Mot de passe",
        }
    },

    breadcrumbSteps: {
        registration: "Register",
        store: "Go into a store",
        form: "Fill in the form",
        survey: "Answer an optional survey",
        cashback: "Get your cashback"
    },

    product: {
        subtitle : "Controleer uw cholesterol",
        macaronPrice : "-€10",
        macaronText : "Bij aankoop van een doos van 90 capsules",
    },

    pageTitles: {
        maxReached: "Maximum aantal inzendingen bereikt",
        wrongCode: "Verkeerde code",
        registration: "Inschrijven",
        alreadySend: "Already send",
        confirmation: "Bevestiging",
    },

    pageLitteracy: {
        maxReached : "Het maximale aantal deelnames is bereikt. Deze actie is daarom niet meer toegankelijk.",
        wrongCode: "De opgegeven code is verkeerd. U moet de link gebruiken die u door Astel Medica wordt toegestuurd. Maar misschien wil je je inschrijven voor de actie?",
        signup: "Sign up!",
        alreadySend: "You have already send your participation",
        registration : {
            introProduct : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vitae erat ut tortor tempus ornare ut eu turpis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Suspendisse potenti. Mauris fermentum sem leo, eget hendrerit diam aliquet a. Duis odio mauris, feugiat a malesuada sit amet, gravida eget odio. Integer eu suscipit dui. Cras faucibus est ",
            linkToClientSite : "sed suscipit suscipit",
            byRegistering: "By registering to this action, I agree with the",
            privacyNotice: "privacy notice",
            claimMail : "Claim via email",
            claimWhatsapp: "Claim via Whatsapp",
            claimSms: "Claim via SMS",
            or: "Or"
        },
        confirmInscription: {
            thanks: "Merci pour votre inscription !",
            smsLine1 :"We’ve just sent you an SMS with all the details.",
            smsLine2: "Go check it out!",
            whatsappLine1: "Check your phone",
            whatsappLine2: "All instruction have been sent via Whatsapp",
            mailLine1: "We’ve just sent you an e-mail with all the details.",
            mailLine2: "Go check it out!"
        },
        landing: {
            aboutYou: "Tell us more about you",
            aboutProduct: "About our product",
            popTitle : "Proof of Purcahse",
            agree: "I agree with",
            termsAndConditions: "general terms & conditions",
            moreInfo: "Send me more information about CLIENT actions",
            moreInfoHelper: "We'll send you on average one e-mail every two month",
            claimCashback: "Claim my cashback",
        }
    },

    inputLabel: {
     defaultListHelper: "Choose one in the list",
     mandatoryFields: "Mandatory Fields",
     typeFirstname : "Write here your FIRSTNAME",
     typeLastname : "Write here your LASTNAME",
     typeEmail : "Put your e-mail adres here",
     typeIban : "Write your IBAN number here",
        // https://vue-i18n.intlify.dev/guide/essentials/syntax.html#literal-interpolation
     helpEmail : "You must have an {'@'} in your email",
     typePhone : "Write your mobile number",
     helpPhone : "With the international code, please",
     typePassword : "Type your password",
     helpPassword : "It must contains X, Y and Z",
     typeUniqueCode : "Indiquez ici le code visible sur le ticket de caisse",
     typeTitle : "Titre",
     helperTitle : "Cela servira uniquement dans nos communications",

     upload: {
        state: "state",
        uploadYourFile: "Upload your files",
        clickOrDrop: "Click or drop your ticket here.",
        remove: "Remove",
        waiting: "Waiting for upload…",
        uploaded: "Uploaded!",
        error: "Error",
        uploadsDone: "Upload successful",
        onlyImages: "Only images and pdf are accepted.",
        maxSize: "Maximum size of 20Mo.",
        uploadFile: "Upload file",
        generalError: "Something went wrong, please try again later"
    }
},

    // start a new object for every topic
players: {
        // tableheaders
    table: {

    },
        // form-labels
    form: {

    },
        //error-messages
    errors: {

    }
}
}
