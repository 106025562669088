<template>
    <div v-if="langswitchsThatMustBePresent.length >= 1 && modelLangButton === 'country'">
        <div class="langswitcher" :class="{inheader: inHeader, outheader: !inHeader}" v-if="langswitchsThatMustBePresent.length > 1 ">

            <lang-button tabindex="0" aria-label="Belgique - français" label="BE-FR" tolocale="fr_BE" v-if="langswitchsThatMustBePresent.includes('FR_BE')" :isActive="$i18n.locale === 'fr_BE'"></lang-button>
            <lang-button tabindex="0" aria-label="België - nederlands" label="BE-NL" tolocale="nl_BE" v-if="langswitchsThatMustBePresent.includes('NL_BE')" :isActive="$i18n.locale === 'nl_BE'"></lang-button>
            <lang-button tabindex="0" aria-label="Belgium - english" label="BE-EN" tolocale="en_BE" v-if="langswitchsThatMustBePresent.includes('EN_BE')" :isActive="$i18n.locale === 'en_BE'"></lang-button>

            <lang-button tabindex="0" aria-label="Luxembourg" label="LU-FR" tolocale="fr_LU" v-if="langswitchsThatMustBePresent.includes('FR_LU')" :isActive="$i18n.locale === 'fr_LU'"></lang-button>
            <lang-button tabindex="0" aria-label="France" label="FR-FR" tolocale="fr_FR" v-if="langswitchsThatMustBePresent.includes('FR_FR')" :isActive="$i18n.locale === 'fr_FR'"></lang-button>

            <lang-button tabindex="0" aria-label="Nederland" label="NL-NL" tolocale="nl_NL" v-if="langswitchsThatMustBePresent.includes('NL_NL')" :isActive="$i18n.locale === 'nl_NL'"></lang-button>

            <lang-button tabindex="0" aria-label="Deutschland" label="DE-DE" tolocale="de_DE" v-if="langswitchsThatMustBePresent.includes('DE_DE')" :isActive="$i18n.locale === 'de_DE'"></lang-button>
            <lang-button tabindex="0" aria-label="Schweiz" label="CH-DE" tolocale="de_CH" v-if="langswitchsThatMustBePresent.includes('DE_CH')" :isActive="$i18n.locale === 'de_CH'"></lang-button>
            <lang-button tabindex="0" aria-label="Suisse" label="CH-FR" tolocale="fr_CH" v-if="langswitchsThatMustBePresent.includes('FR_CH')" :isActive="$i18n.locale === 'fr_CH'"></lang-button>
            <lang-button tabindex="0" aria-label="England" label="UK-EN" tolocale="en_UK" v-if="langswitchsThatMustBePresent.includes('EN_UK')" :isActive="$i18n.locale === 'en_UK'"></lang-button>

        </div>
    </div>

    <div v-else-if="langswitchsThatMustBePresent.length >= 1 && modelLangButton === 'language'">
        <div class="langswitcher" :class="{inheader: inHeader, outheader: !inHeader}" v-if="langswitchsThatMustBePresent.length > 1 ">
            <lang-button tabindex="0" aria-label="français" label="FR" tolocale="fr_BE" v-if="langswitchsThatMustBePresent.some(i=> ['FR_BE', 'FR_FR', 'FR_LU', 'FR_CH'].includes(i))" :isActive="$i18n.locale === 'fr_BE' || $i18n.locale === 'fr_FR' || $i18n.locale === 'fr_LU' || $i18n.locale === 'fr_CH'"></lang-button>
            <lang-button tabindex="0" aria-label="nederlands" label="NL" tolocale="nl_BE" v-if="langswitchsThatMustBePresent.some(i=> ['NL_BE', 'NL_NL'].includes(i))" :isActive="$i18n.locale === 'nl_BE' || $i18n.locale === 'nl_NL'"></lang-button>
            <lang-button tabindex="0" aria-label="deutsch" label="DE" tolocale="de_DE" v-if="langswitchsThatMustBePresent.some(i=> ['DE_BE', 'DE_DE', 'DE_CH'].includes(i))" :isActive="$i18n.locale === 'de_BE' || $i18n.locale === 'de_DE' || $i18n.locale === 'de_CH'"></lang-button>
            <lang-button tabindex="0" aria-label="english" label="EN" tolocale="en_BE" v-if="langswitchsThatMustBePresent.some(i=> ['EN_BE', 'EN_UK'].includes(i))" :isActive="$i18n.locale === 'en_BE' || $i18n.locale === 'en_UK'"></lang-button>
        </div>
    </div>

    <!-- DEFAULT lang in case of maintenance -->
    <div v-else class="langswitcher" :class="{inheader: inHeader, outheader: !inHeader}">
        <lang-button aria-label="français" tabindex="0" label="FR" tolocale="fr_BE" :isActive="$i18n.locale === 'fr_BE'"></lang-button>
        <lang-button aria-label="nederlands" tabindex="0" label="NL" tolocale="nl_BE" :isActive="$i18n.locale === 'nl_BE'"></lang-button>
    </div>
</template>

<!-- ================================================================================== -->

<script>

    import LangButton from '@/components/LangButton';
    import Mixins from "../mixins/NonAutomatedData";

    export default {
        name:"langswitcherGDPR",

        props: {
            inHeader: {
                type: Boolean,
                required: false,
                default: true,
            }
        },

        components: {
            LangButton
        },
        mixins: [Mixins],

        data(){
            return {
                langswitchsThatMustBePresent: this.$store.getters.getLangList,
            }
        },

        watch: {
            // To be sure it load at startup
            '$store.state.langList': function(/* newLocale, oldLocale */){
                this.langswitchsThatMustBePresent = this.$store.getters.getLangList;
            },
        },
    }
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
</style>
