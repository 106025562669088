import {createI18n} from "vue-i18n";
import { en } from './lang/en'
import { fr } from './lang/fr'
import { nl } from './lang/nl'
import { de } from './lang/de'
import { nlnl } from './lang/nl_nl'
import { nlje } from './lang/nl_je_form'
import { nlnlje } from './lang/nl_nl_je_form'

// true => use "Je" translations | false => use "Uw" translations
let useJeInsteadOfUw = false;

export default createI18n({
    warnHtmlInMessage: "off", // used to disable warn messages in console about missing translation
    locale: 'nl_BE', // default locale
    fallbackLocale: 'fr_BE', // fallback locale if default locale is not working

    messages : {
        'en_BE' : en,
        'en_UK' : en,
        'fr_BE' : fr,
        'de_DE': de,
        'fr_LU' : fr,
        'nl_BE' : useJeInsteadOfUw ? nlje : nl,
        'nl_NL' : useJeInsteadOfUw ? nlnlje : nlnl, 
    }
});
