// This file is intended to receive the new functions that must be developed for one singular action
// So it is isolated from the rest and should reduce the merge conflicts (maybe).
// Later, those functions could be cleaned and integrated into the Master if useful.
export default {
    data () {
        return {
            // introduce new data
        }
    },
    methods: {
        // introduce new methods
    },
    // etc.
}
